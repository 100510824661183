import { Component, Input, OnInit } from '@angular/core';
import {
  ChargingStateEnum,
  OccupancyStateEnum,
  SpaceInfo,
  UsageState,
} from '../../../models/space-status';
import { Reservation } from '../../../models/reservation';

@Component({
  selector: 'app-space',
  templateUrl: './space.component.html',
  styleUrls: ['./space.component.css'],
})
export class SpaceComponent implements OnInit {
  chargingImageName: string | undefined;
  usageImageName: string | undefined;
  backgroundImageName: string | undefined;

  //enum references
  chargeStateEnum: typeof ChargingStateEnum = ChargingStateEnum;
  occupancySateEnum: typeof OccupancyStateEnum = OccupancyStateEnum;
  usageSateEnum: typeof UsageState = UsageState;

  @Input() spaceInfo: SpaceInfo | undefined;

  constructor() {
    let reservation: Reservation = {
      bookingId: -1,
      bookingEndTime: '',
      bookingReference: '',
      bookingStartTime: '',
      bookingStatus: '',
      bookingTime: '',
      firstName: '',
      lastName: '',
      licensePlate: '',
      paid: '',
      place: '',
      spaceNr: '',
      vat: 0,
    };

    this.spaceInfo = {
      uuidForAngularTrackBy: '',
      spaceNumber: -1,
      systemId: -1,
      spaceStatus: {
        occupancy: {
          status: OccupancyStateEnum.NOT_AVAILABLE,
          startTime: '',
          userRegistration: '',
        },
        usage: UsageState.NOT_AVAILABLE,
        charging: {
          status: ChargingStateEnum.NOT_AVAILABLE,
          startTime: '',
        },
      },
      selected: false,
      reservation: reservation,
    };

    this.backgroundImageName = 'Notinuse-square.svg';
  }

  ngOnInit(): void {
    // switch for charging status images to be displayed
    switch (this.spaceInfo?.spaceStatus.charging.status) {
      case ChargingStateEnum.CHARGING: {
        this.chargingImageName = 'Property%201=Working.svg';
        break;
      }
      case ChargingStateEnum.AVAILABLE: {
        this.chargingImageName = 'Property%201=Waiting.svg';
        break;
      }
      case ChargingStateEnum.OUTOFORDER: {
        this.chargingImageName = 'Property%201=Out%20of%20order.svg';
        break;
      }
      case ChargingStateEnum.STOPPED: {
        this.chargingImageName = 'Property%201=Stopped.svg';
        break;
      }
      case ChargingStateEnum.WAITING: {
        this.chargingImageName = 'Property%201=Default.svg';
        break;
      }
    }

    // switch for usage status images to be displayed
    switch (this.spaceInfo?.spaceStatus.usage) {
      case UsageState.VALID: {
        this.usageImageName = 'In use1.svg';
        this.backgroundImageName = 'In use-square.svg';
        break;
      }
      case UsageState.WAITING: {
        this.usageImageName = 'Waiting.svg';
        break;
      }
      case UsageState.CHECK: {
        this.usageImageName = 'Check.svg';
        this.backgroundImageName = 'Check-square.svg';
        break;
      }
      case UsageState.INVALID: {
        this.usageImageName = 'Error1.svg';
        this.backgroundImageName = 'Error-square.svg';
        break;
      }
      case UsageState.UNREGISTERED: {
        this.usageImageName = 'Sensor - 1.svg';
        break;
      }
    }
  }
}
