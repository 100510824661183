<clr-main-container>
  <clr-header class="header-6">
    <div class="branding">
      <a href="javascript://">
        <cds-icon shape="sas-icon"></cds-icon>
        <span class="title">Save-a-Space</span>
      </a>
    </div>
  </clr-header>

  <div class="vertical-center">
    <div class="text-center">
      <h1>Oops! Page Not Found</h1>
      <p>Sorry, but the page you are looking for does not exist.</p>
      <button class="btn" onclick="history.back()">Back</button>
    </div>
  </div>
</clr-main-container>
