import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BookingsService } from 'src/app/services/bookings.service';
import { ReservationDetails } from 'src/app/models/reservation';
import * as dayjs from 'dayjs';
import * as duration from 'dayjs/plugin/duration';

@Component({
  selector: 'app-booking-details',
  templateUrl: './booking-details.component.html',
  styleUrls: ['./booking-details.component.css'],
})
export class BookingDetailsComponent implements OnInit {
  booking: ReservationDetails | undefined;
  history: any[] = [];
  loading: boolean = true;

  constructor(
    private _route: ActivatedRoute,
    private _sasBookingsService: BookingsService
  ) {
    dayjs.extend(duration);
  }

  ngOnInit(): void {
    const routeParams = this._route.snapshot.paramMap;
    const bookingIdFromRoute = Number(routeParams.get('bookingId'));

    this._sasBookingsService
      .getBookingHistory(bookingIdFromRoute)
      .subscribe((bookingHistory) => {
        this.loading = true;

        let details = bookingHistory.at(-1);
        console.log(details);

        if (details) {
          this.booking = <ReservationDetails>{
            bookingId: details.bookingId,
            bookingReference: details.bookingReference,
            licensePlate: details.bookingId.vehicleId.registrationNumber,
            bookingStartTime: details.startTime,
            bookingEndTime: details.endTime,
            bookingDuration: dayjs
              .duration(dayjs(details.endTime).diff(dayjs(details.startTime)))
              .format('H:mm'),
            bookingTime: details.creationTime,
            bookingStatus: details.status.replace('BOOKING_', ''),
            lastName: details.bookingId.driverId.userId.lastName,
            firstName: details.bookingId.driverId.userId.firstName,
            place: details.bookingId.parkingSiteId.name,
            spaceNr: !!details.bookingId.parkingSpotId
              ? details.bookingId.parkingSpotId.spotNumber
              : 'N/A',
            paid: details.bookingId.totalCost ?? 0,
            vat:
              details.bookingId.organisationId.vatAmount > 0
                ? (details.bookingId.totalCost ?? 0) -
                  (details.bookingId.totalCost ?? 0) /
                    (1 + details.bookingId.organisationId.vatAmount / 100)
                : 0,
            email: details.bookingId.driverId.userId.email,
            phoneNr: details.bookingId.driverId.userId.contact,
            vehicleMake: details.bookingId.vehicleId.make,
            vehicleModel: details.bookingId.vehicleId.model,
            vehicleType: details.bookingId.vehicleId.vehicleType,
            paymentStatus: details.paymentStatus.replace('PAYMENT_', ''),
          };

          this.history = bookingHistory;
        }

        this.loading = false;
      });
  }
}
