import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SpacesService {
  constructor(private _http: HttpClient) {}

  getSpaces(placesId: number): Observable<any[]> {
    return this._http.get<any[]>(
      `${environment.sasAPIendpoint}/parkingsites/${placesId}/parkingspots`
    );
  }
}
