import { Injectable } from '@angular/core';
import { AuthService } from '../../services/auth.service';

declare const EventSourcePolyfill: any;

@Injectable({
  providedIn: 'root',
})
export class SseService {
  constructor(private _authService: AuthService) {}

  getEventSource(url: string): EventSource {
    const authToken = this._authService.currentUserValue.token;

    return new EventSourcePolyfill(url, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
  }
}
