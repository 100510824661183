import { Reservation } from './reservation';

export enum OccupancyStateEnum {
  'OCCUPIED' = 'OCCUPIED',
  'FREE' = 'FREE',
  'NOT_AVAILABLE' = 'NOT_AVAILABLE',
}

export enum ChargingStateEnum {
  'AVAILABLE' = 'AVAILABLE',
  'WAITING' = 'WAITING',
  'CHARGING' = 'CHARGING',
  'STOPPED' = 'STOPPED',
  'OUTOFORDER' = 'OUTOFORDER',
  'NOT_AVAILABLE' = 'NOT_AVAILABLE',
}

export enum UsageState {
  'UNREGISTERED' = 'UNREGISTERED',
  'WAITING' = 'WAITING',
  'VALID' = 'VALID',
  'CHECK' = 'CHECK',
  'INVALID' = 'INVALID',
  'NOT_AVAILABLE' = 'NOT_AVAILABLE',
}

export type OccupancyState = {
  status: OccupancyStateEnum;
  startTime: string;
  userRegistration: string;
};

export type ChargingState = {
  status: ChargingStateEnum;
  startTime: string;
};

export type SpaceStatus = {
  occupancy: OccupancyState;
  usage: UsageState;
  charging: ChargingState;
};

export type SpaceInfo = {
  uuidForAngularTrackBy: string;
  spaceNumber: number;
  systemId: number;
  spaceStatus: SpaceStatus;
  selected: boolean;
  reservation: Reservation;
};
