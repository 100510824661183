import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { share } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class StorageService implements OnDestroy {
  private onSubject = new Subject<{ key: string; value: any }>();
  public changes = this.onSubject.asObservable().pipe(share());

  constructor() {
    this.start();
  }

  ngOnDestroy() {
    this.stop();
  }

  public getStorage() {
    let s = [];
    let value;
    let item;
    for (let i = 0; i < localStorage.length; i++) {
      value = localStorage.key(i);
      console.log(value);

      if (value != null) {
        item = localStorage.getItem(value);
        console.log(item);
      }

      if (item != null) {
        s.push({
          key: localStorage.key(i),
          value: item,
        });
      }
    }
    return s;
  }

  public store(key: string, data: any): void {
    localStorage.setItem(key, JSON.stringify(data));
    // the local application doesn't seem to catch changes to localStorage...
    this.onSubject.next({ key: key, value: data });
  }

  public clear(key: string) {
    localStorage.removeItem(key);
    // the local application doesn't seem to catch changes to localStorage...
    this.onSubject.next({ key: key, value: null });
  }

  private start(): void {
    window.addEventListener('storage', this.storageEventListener.bind(this));
    console.log('storage event listener start called');
  }

  private storageEventListener(event: StorageEvent) {
    console.log('Storage event detected');
    if (event.storageArea == localStorage) {
      let v;

      if (event.newValue != null) {
        try {
          v = JSON.parse(event.newValue);
        } catch (e) {
          v = event.newValue;
        }

        if (event.key != null) {
          this.onSubject.next({ key: event.key, value: v });
        }
      }
    }
  }

  private stop(): void {
    window.removeEventListener('storage', this.storageEventListener.bind(this));
    this.onSubject.complete();
  }
}
