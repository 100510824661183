import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  constructor(private _http: HttpClient) {}

  getOccupancyAnalytics(
    identifiedAreaId: number,
    fromDate: string,
    toDate: string,
    dataPoints: number
  ) {
    let params = new HttpParams();

    params = params.append('parkingSectionId', identifiedAreaId);
    params = params.append('fromDate', fromDate);
    params = params.append('toDate', toDate);
    params = params.append('dataPoints', dataPoints);

    console.log(params.toString());

    return this._http.get<any[]>(`${environment.sasAPIendpoint}/analytics`, {
      params,
    });
  }

  getChargerOccupancyAnalytics(
    identifiedAreaId: number,
    fromDate: string,
    toDate: string,
    dataPoints: number
  ) {
    let params = new HttpParams();

    params = params.append('parkingSectionId', identifiedAreaId);
    params = params.append('fromDate', fromDate);
    params = params.append('toDate', toDate);
    params = params.append('dataPoints', dataPoints);

    console.log(params.toString());

    return this._http.get<any[]>(
      `${environment.sasAPIendpoint}/analytics/chargerusagegraph`,
      {
        params,
      }
    );
  }

  getOccupancyGaugeAnalytics(identifiedAreaId: number) {
    let params = new HttpParams();

    params = params.append('parkingSectionId', identifiedAreaId);

    console.log(params.toString());

    return this._http.get<any[]>(
      `${environment.sasAPIendpoint}/analytics/occupancygauge`,
      {
        params,
      }
    );
  }

  getViolationsGaugeAnalytics(identifiedAreaId: number) {
    let params = new HttpParams();

    params = params.append('parkingSectionId', identifiedAreaId);

    console.log(params.toString());

    return this._http.get<any[]>(
      `${environment.sasAPIendpoint}/analytics/violationsgauge`,
      {
        params,
      }
    );
  }
}
