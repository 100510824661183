import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { ClarityModule } from '@clr/angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { MainComponent } from './main/main.component';
import { DashboardComponent } from './main/dashboard/dashboard.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthInterceptor } from './http-interceptors/auth.interceptor';
import { ErrorInterceptor } from './http-interceptors/error.interceptor';
import { SpaceComponent } from './shared/components/space/space.component';
import { SpaceInformationComponent } from './shared/components/space-information/space-information.component';

import '@cds/core/alert/register.js';
import { CdsModule } from '@cds/angular';
import { SpaceStatusComponent } from './main/space-status/space-status.component';
import { BookingsHistoryComponent } from './main/bookings-history/bookings-history.component';
import { SignupComponent } from './signup/signup.component';
import { TimeagoModule } from 'ngx-timeago';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { BookingDetailsComponent } from './main/booking-details/booking-details.component';
import { CurrentBookingsComponent } from './main/current-bookings/current-bookings.component';
import { NotfoundComponent } from './error/notfound/notfound.component';
import { NotauthorisedComponent } from './error/notauthorised/notauthorised.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    MainComponent,
    DashboardComponent,
    SpaceComponent,
    SpaceInformationComponent,
    SpaceStatusComponent,
    BookingsHistoryComponent,
    SignupComponent,
    BookingDetailsComponent,
    CurrentBookingsComponent,
    NotfoundComponent,
    NotauthorisedComponent,
  ],
  imports: [
    BrowserModule,
    ClarityModule,
    BrowserAnimationsModule,
    FormsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    CdsModule,
    TimeagoModule.forRoot(),
    NgxChartsModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
