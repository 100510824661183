import { Component, OnInit } from '@angular/core';
import { LegendPosition } from '@swimlane/ngx-charts';
import { AnalyticsService } from '../../shared/services/analytics.service';
import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
import { PlaceService } from '../../services/place.service';
import { IdentifiedAreasService } from '../../services/identified-areas.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit {
  occupancyHistoryData: any[] | undefined;
  occupancyGaugeData: any[] | undefined;
  violationsGaugeData: any[] | undefined;

  legend: boolean = true;
  legendPosition: LegendPosition = LegendPosition.Below;
  showLabels: boolean = true;
  animations: boolean = true;
  xAxis: boolean = true;
  yAxis: boolean = true;
  showYAxisLabel: boolean = true;
  showXAxisLabel: boolean = true;
  xAxisLabel: string = 'Date';
  yAxisLabel: string = 'Percentage';
  timeline: boolean = true;
  timePeriodOption: any;
  placesOption: any;
  places: any;
  zonesOption: any;
  identifiedAreas: any;
  customColours: any[] | undefined;
  yScaleMax: number = 100;
  yScaleMin: number = 0;

  constructor(
    private _sasAnalyticsService: AnalyticsService,
    private _sasPlaceService: PlaceService,
    private _sasIdentifiedAreaService: IdentifiedAreasService
  ) {
    dayjs.extend(utc);
  }

  axisFormat(value: any) {
    return value;
  }

  ngOnInit(): void {
    this.timePeriodOption = 'one';

    this._sasPlaceService.getPlaces().subscribe((places) => {
      this.places = places;
      this.placesOption = places[0].parkingSite.id;
      this.onPlacesOptionChange(this.placesOption);
    });
  }

  onTimePeriodOptionChange(timePeriodId: any) {
    console.log('Time Period: ' + timePeriodId);

    this.timePeriodOption = timePeriodId;

    this.updateOccupancyHistoryData();
  }

  updateOccupancyHistoryData() {
    let fromDate: dayjs.Dayjs;
    let toDate: dayjs.Dayjs;
    let dataPoints: number;

    fromDate = dayjs().startOf('day');
    toDate = dayjs().endOf('day');
    dataPoints = 24;

    if (this.timePeriodOption === 'two') {
      fromDate = dayjs().subtract(1, 'day').startOf('day');
      toDate = dayjs().subtract(1, 'day').endOf('day');
      dataPoints = 24;
    }
    if (this.timePeriodOption === 'three') {
      fromDate = dayjs().startOf('week');
      toDate = dayjs().endOf('week');
      dataPoints = 7;
    }
    if (this.timePeriodOption === 'four') {
      fromDate = dayjs().subtract(1, 'week').startOf('week');
      toDate = dayjs().subtract(1, 'week').endOf('week');
      dataPoints = 7;
    }
    if (this.timePeriodOption === 'five') {
      fromDate = dayjs().startOf('month');
      toDate = dayjs().endOf('month');
      dataPoints = fromDate.daysInMonth();
    }
    if (this.timePeriodOption === 'six') {
      fromDate = dayjs().subtract(1, 'month').startOf('month');
      toDate = dayjs().subtract(1, 'month').endOf('month');
      dataPoints = fromDate.daysInMonth();
    }

    let identifiedAreaId = this.zonesOption;

    console.log(
      `Dates from: ${fromDate.toISOString()} to: ${toDate.toISOString()}`
    );

    this._sasAnalyticsService
      .getOccupancyAnalytics(
        identifiedAreaId,
        fromDate.toISOString(),
        toDate.toISOString(),
        dataPoints
      )
      .subscribe((analytics) => {
        let occupancySeries = analytics[0].series;
        console.log(occupancySeries);

        this._sasAnalyticsService
          .getChargerOccupancyAnalytics(
            identifiedAreaId,
            fromDate.toISOString(),
            toDate.toISOString(),
            dataPoints
          )
          .subscribe((chargerAnalytics) => {
            console.log(chargerAnalytics);

            if (
              this.timePeriodOption === 'three' ||
              this.timePeriodOption === 'four'
            ) {
              this.occupancyHistoryData = occupancySeries.map(
                (item: any, i: number) => ({
                  name: dayjs(item.name).format('ddd DD'),
                  series: [
                    {
                      name: 'Occupancy',
                      value: item.value,
                    },
                    {
                      name: 'EV charging',
                      value: chargerAnalytics[i].value,
                    },
                  ],
                })
              );
            } else if (
              this.timePeriodOption === 'five' ||
              this.timePeriodOption === 'six'
            ) {
              this.occupancyHistoryData = occupancySeries.map(
                (item: any, i: number) => ({
                  name: dayjs(item.name).format('ddd DD'),
                  series: [
                    {
                      name: 'Occupancy',
                      value: item.value,
                    },
                    {
                      name: 'EV charging',
                      value: chargerAnalytics[i].value,
                    },
                  ],
                })
              );
            } else {
              this.occupancyHistoryData = occupancySeries.map(
                (item: any, i: number) => ({
                  name: `${dayjs(item.name).format('HH')}:00 - ${dayjs(
                    item.name
                  )
                    .add(1, 'hour')
                    .format('HH')}:00`,
                  series: [
                    {
                      name: 'Occupancy',
                      value: item.value,
                    },
                    {
                      name: 'EV charging',
                      value: chargerAnalytics[i].value,
                    },
                  ],
                })
              );
            }

            console.log(this.occupancyHistoryData);

            this.customColours = [
              {
                name: 'Occupancy',
                value: '#4024F3',
              },
              {
                name: 'EV charging',
                value: '#6C6CE5',
              },
            ];

            // this.customColours = this.occupancyHistoryData?.map(
            //   (item: any) => ({
            //     name: item.name,
            //     value: '#4024F3',
            //   })
            // );
          });
      });
  }

  updateOccupancyGauge() {
    let identifiedAreaId = this.zonesOption;

    this._sasAnalyticsService
      .getOccupancyGaugeAnalytics(identifiedAreaId)
      .subscribe((analyticsData) => {
        console.log('Occupancy Gauge: ' + analyticsData);
        this.occupancyGaugeData = analyticsData;
      });
  }

  updateViolationsGauge() {
    let identifiedAreaId = this.zonesOption;

    this._sasAnalyticsService
      .getViolationsGaugeAnalytics(identifiedAreaId)
      .subscribe((analyticsData) => {
        console.log('Occupancy Gauge: ' + analyticsData);
        this.violationsGaugeData = analyticsData;
      });
  }

  onPlacesOptionChange(placeId: number) {
    this._sasIdentifiedAreaService
      .getIdentifiedAreas(placeId)
      .subscribe((identifiedAreas) => {
        this.identifiedAreas = identifiedAreas;
        this.zonesOption = identifiedAreas[0].id;
        this.onZonesOptionChange(this.zonesOption);
      });
  }

  onZonesOptionChange(identifiedAreaId: number) {
    console.log(`Identified area: ${identifiedAreaId}`);

    this.zonesOption = identifiedAreaId;

    this.updateOccupancyHistoryData();
    this.updateOccupancyGauge();
    this.updateViolationsGauge();
  }

  formatDataLabel(value: any) {
    return value.toFixed(2) + '%';
  }
}
