<div
  *ngIf="newAlerts !== undefined && newAlerts.length > 0"
  class="alert alert-app-level alert-warning"
  role="alert"
>
  <div class="alert-items">
    <div class="alert-item static">
      <div class="alert-icon-wrapper">
        <cds-icon class="alert-icon" shape="exclamation-triangle"></cds-icon>
      </div>
      <div class="alert-text">
        There are {{ (newAlerts.length - 1) / 23 }} new space violations to
        review.
      </div>
      <div class="alert-actions">
        <button class="btn alert-action" (click)="clearAlertArray()">
          Clear
        </button>
      </div>
    </div>
  </div>
</div>
<clr-main-container>
  <clr-header class="header-6">
    <div class="branding">
      <a href="javascript://">
        <cds-icon shape="sas-icon"></cds-icon>
        <span class="title">Save-a-Space</span>
      </a>
    </div>
    <div class="header-actions">
      <a
        class="nav-link nav-text"
        (click)="onLogout()"
        routerLink="/loginWindow"
        routerLinkActive="active"
      >
        Log Out
      </a>
    </div>
  </clr-header>
  <div class="content-container">
    <main class="content-area">
      <router-outlet></router-outlet>
    </main>
    <ng-template [ngIf]="sideNavPermittedRoles.includes(currentUser.role)">
      <nav class="sidenav" [clr-nav-level]="1">
        <section class="sidenav-content">
          <a class="nav-link" routerLink="dashboard" routerLinkActive="active">
            Dashboard
          </a>
          <section class="nav-group collapsible">
            <input id="live-data" type="checkbox" />
            <label for="live-data">Live data</label>
            <ul class="nav-list">
              <li>
                <a
                  class="nav-link"
                  routerLink="spaceStatus"
                  routerLinkActive="active"
                >
                  Spaces status
                </a>
              </li>
              <li>
                <a
                  class="nav-link"
                  routerLink="currentBookings"
                  routerLinkActive="active"
                  >Current bookings</a
                >
              </li>
            </ul>
          </section>
          <section class="nav-group collapsible">
            <input id="setup" type="checkbox" />
            <label for="setup">Setup</label>
            <ul class="nav-list">
              <li><a class="nav-link">Parking configuration</a></li>
              <li><a class="nav-link">Tariffs</a></li>
              <li><a class="nav-link">Rights management</a></li>
            </ul>
          </section>
          <section class="nav-group collapsible">
            <input id="analysis" type="checkbox" />
            <label for="analysis">Analysis</label>
            <ul class="nav-list">
              <li>
                <a
                  class="nav-link"
                  routerLink="bookingsHistory"
                  routerLinkActive="active"
                >
                  Bookings
                </a>
              </li>
              <li>
                <a class="nav-link"> Violations & Problem reports </a>
              </li>
              <li>
                <a class="nav-link"> Occupancy </a>
              </li>
            </ul>
          </section>
        </section>
      </nav>
    </ng-template>
  </div>
</clr-main-container>
