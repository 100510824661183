import { Component, ViewChild } from '@angular/core';
import {
  ClrDatagrid,
  ClrDatagridStateInterface,
  ClrDatagridStringFilterInterface,
  ClrLoadingState,
} from '@clr/angular';
import { Reservation } from '../../models/reservation';
import { BookingsService } from '../../services/bookings.service';
import * as dayjs from 'dayjs';

// class BookingStatusFilter
//   implements ClrDatagridStringFilterInterface<Reservation>
// {
//   accepts(item: Reservation, search: string): boolean {
//     console.log(item);
//     return item.bookingStatus.toLowerCase().indexOf(search) >= 0;
//   }
// }

@Component({
  selector: 'app-bookings-history',
  templateUrl: './bookings-history.component.html',
  styleUrls: ['./bookings-history.component.css'],
})
export class BookingsHistoryComponent {
  @ViewChild(ClrDatagrid) dg: ClrDatagrid | undefined;

  // bookingStatusFilter = new BookingStatusFilter();
  bookings: Reservation[] = [];
  total: number = 20;
  loading: boolean = true;
  fromDate: Date;
  toDate: Date;
  dataGridState: ClrDatagridStateInterface | undefined;
  filters: { [prop: string]: any[] } = {};
  downloadBtnState: ClrLoadingState = ClrLoadingState.DEFAULT;

  constructor(private _sasBookingsService: BookingsService) {
    this.fromDate = dayjs().startOf('day').toDate();
    this.toDate = dayjs().endOf('day').toDate();
  }

  refreshFromDataGridEvent(state: ClrDatagridStateInterface) {
    this.dataGridState = state;

    this.updateBookings();
  }

  updateBookings() {
    this.loading = true;

    // If the start index is negative the server will return a 500
    // causing our datagrid to brick, so we make any negatives 0
    if (this.dataGridState?.page?.from && this.dataGridState!.page.from < 0) {
      this.dataGridState.page.from = 0;
    }

    // We convert the filters from an array to a map,
    // because that's what our backend-calling service is expecting
    if (this.dataGridState?.filters) {
      for (let filter of this.dataGridState.filters) {
        let { property, value } = <{ property: string; value: string }>filter;
        this.filters[property] = [value];
      }
    }

    console.log(this.dataGridState);
    console.log(this.filters['status']);

    if (this.filters['status'] == undefined) {
      this.filters['status'] = [''];
    }

    this._sasBookingsService
      .getBookings(
        dayjs(this.fromDate).toISOString(),
        dayjs(this.toDate).toISOString(),
        this.dataGridState?.page?.from,
        this.dataGridState?.page?.size,
        this.filters['status'][0]
      )
      .subscribe((bookings) => {
        if (
          typeof bookings.totalSize === 'string' ||
          bookings.totalSize instanceof String
        ) {
          this.total = parseInt(bookings.totalSize);
        } else {
          this.total = bookings.totalSize;
        }

        console.log(bookings);

        let paginatedBookings: any[] = bookings.paginatedBookings;

        this.bookings = paginatedBookings.map(
          (booking) =>
            <Reservation>{
              bookingId: booking.id,
              bookingReference: booking.bookingReference,
              licensePlate: booking.vehicleId.registrationNumber,
              bookingStartTime: booking.startTime,
              bookingEndTime: booking.endTime,
              bookingTime: booking.creationTime,
              bookingStatus: booking.status.replace('BOOKING_', ''),
              lastName: booking.driverId.userId.lastName,
              firstName: booking.driverId.userId.firstName,
              place: booking.parkingSiteId.name,
              spaceNr: !!booking.parkingSpotId
                ? booking.parkingSpotId.spotNumber
                : 'N/A',
              paid: booking.totalCost ?? 0,
              vat:
                booking.organisationId.vatAmount > 0
                  ? (booking.totalCost ?? 0) -
                    (booking.totalCost ?? 0) /
                      (1 + booking.organisationId.vatAmount / 100)
                  : 0,
            }
        );
        // .filter(
        //   (booking) =>
        //     booking.bookingStatus
        //       .toLowerCase()
        //       .indexOf(filters['status'][0]) >= 0
        // );

        this.loading = false;

        setTimeout(() => this.dg?.resize());
      });
  }

  fromDateChanged($event: Date) {
    console.log(`${$event}`);
    this.fromDate = dayjs($event).startOf('day').toDate();
    this.updateBookings();
  }

  toDateChanged($event: Date) {
    console.log(`${$event}`);
    this.toDate = dayjs($event).endOf('day').toDate();
    this.updateBookings();
  }

  downloadCSV() {
    console.log('Downloading...');
    this.downloadBtnState = ClrLoadingState.LOADING;
    this._sasBookingsService
      .getBookingsCSV(
        dayjs(this.fromDate).toISOString(),
        dayjs(this.toDate).toISOString(),
        this.filters['status'][0]
      )
      .subscribe((data) => {
        console.log(data);

        let downloadURL = window.URL.createObjectURL(data);
        let link = document.createElement('a');
        link.href = downloadURL;
        link.download = `Save-a-Space_report_${dayjs(this.fromDate).format(
          'YYYY-MM-DD'
        )}_${dayjs(this.toDate).format('YYYY-MM-DD')}.csv`;
        link.click();
        window.URL.revokeObjectURL(downloadURL);
        this.downloadBtnState = ClrLoadingState.SUCCESS;
      });
  }
}
