<clr-main-container>
  <clr-header class="header-6">
    <div class="branding">
      <a href="javascript://">
        <cds-icon shape="sas-icon"></cds-icon>
        <span class="title">Save-a-Space</span>
      </a>
    </div>
  </clr-header>

  <div class="vertical-center">
    <div class="text-center">
      <h1>Unauthorised</h1>
      <p>Sorry, but you lack the permissions to access this page.</p>
      <button class="btn" onclick="history.go(-2)">Back</button>
    </div>
  </div>
</clr-main-container>
