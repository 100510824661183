import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { first } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Role } from '../models/role';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent {
  error = '';
  loginForm = new FormGroup({
    email: new FormControl(''),
    password: new FormControl(''),
  });

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _authService: AuthService
  ) {}

  onSubmit() {
    console.log(this.loginForm.value);

    const login = this.loginForm.value;

    this._authService
      .login(login.email, login.password)
      .pipe(first())
      .subscribe({
        next: (user) => {
          let returnUrl = this._route.snapshot.queryParams['returnUrl'];

          if (!returnUrl) {
            if (user.role === Role.PEO) {
              returnUrl = '/main/currentBookings';
            } else {
              returnUrl = '/main/dashboard';
            }
          }

          this._router.navigateByUrl(returnUrl).then(() => {});
        },
        error: (error) => {
          this.error = error;
        },
      });
  }
}
