import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { MainComponent } from './main/main.component';
import { DashboardComponent } from './main/dashboard/dashboard.component';
import { AuthGuardService } from './services/auth-guard.service';
import { SpaceStatusComponent } from './main/space-status/space-status.component';
import { BookingsHistoryComponent } from './main/bookings-history/bookings-history.component';
import { BookingDetailsComponent } from './main/booking-details/booking-details.component';
import { CurrentBookingsComponent } from './main/current-bookings/current-bookings.component';
import { NotfoundComponent } from './error/notfound/notfound.component';
import { NotauthorisedComponent } from './error/notauthorised/notauthorised.component';
import { RoleGuardService } from './services/role-guard.service';
import { Role } from './models/role';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  {
    path: 'main',
    component: MainComponent,
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [RoleGuardService],
        data: { roles: [Role.MANAGER, Role.SUPERUSER] },
      },
      {
        path: 'spaceStatus',
        component: SpaceStatusComponent,
        canActivate: [RoleGuardService],
        data: { roles: [Role.MANAGER, Role.SUPERUSER] },
      },
      {
        path: 'bookingsHistory',
        component: BookingsHistoryComponent,
        canActivate: [RoleGuardService],
        data: { roles: [Role.MANAGER, Role.SUPERUSER] },
      },
      {
        path: 'bookingsHistory/:bookingId',
        component: BookingDetailsComponent,
        canActivate: [RoleGuardService],
        data: { roles: [Role.MANAGER, Role.SUPERUSER] },
      },
      {
        path: 'currentBookings',
        component: CurrentBookingsComponent,
      },
    ],
    canActivate: [AuthGuardService],
  },
  { path: '404', component: NotfoundComponent },
  { path: '403', component: NotauthorisedComponent },
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: '**', redirectTo: '/404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
