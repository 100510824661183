import { Injectable, NgZone } from '@angular/core';
import { SseService } from './sse.service';
import { Observable } from 'rxjs';
import { AuthService } from '../../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class SasUpdateService {
  eventSource?: EventSource;

  constructor(
    private _zone: NgZone,
    private _sseService: SseService,
    private _authService: AuthService
  ) {}

  getServerSentEvent(url: string): Observable<any> {
    return new Observable((observer) => {
      this.eventSource = this._sseService.getEventSource(url);
      this.eventSource.onmessage = (event) => {
        console.log('event source event:' + JSON.stringify(event));
        this._zone.run(() => {
          console.log('Zone run');
          observer.next(JSON.parse(JSON.stringify(event)));
        });
      };

      this.eventSource.onerror = (error) => {
        console.log('event source error:' + JSON.stringify(error));

        let errorJSONObject = JSON.parse(JSON.stringify(error));

        if (errorJSONObject.status === 401) {
          this.stopServerSentEvent();
          this._authService.logout();
          location.reload();
        } else {
          this._zone.run(() => {
            observer.next(errorJSONObject);
          });
        }
      };
    });
  }

  stopServerSentEvent() {
    this.eventSource?.close();
  }
}
