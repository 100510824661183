import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PlaceService {
  constructor(private _http: HttpClient) {}

  getPlaces(): Observable<any[]> {
    return this._http.get<any[]>(`${environment.sasAPIendpoint}/parkingsites`);
  }
}
