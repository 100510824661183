import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription, timer } from 'rxjs';
import {
  ChargingStateEnum,
  OccupancyStateEnum,
  SpaceInfo,
  SpaceStatus,
  UsageState,
} from '../../models/space-status';
import { SasUpdateService } from '../../shared/services/sas-update.service';
import { PlaceService } from '../../services/place.service';
import { IdentifiedAreasService } from '../../services/identified-areas.service';
import { SpacesService } from '../../services/spaces.service';
import { SpacesStatusService } from '../../services/spaces-status.service';
import { BookingsService } from '../../services/bookings.service';
import { AuthService } from '../../services/auth.service';
import { StorageService } from '../../shared/services/storage.service';
import { environment } from '../../../environments/environment';
import { UUID } from 'angular2-uuid';

@Component({
  selector: 'app-space-status',
  templateUrl: './space-status.component.html',
  styleUrls: ['./space-status.component.css'],
})
export class SpaceStatusComponent implements OnInit, OnDestroy {
  connectedState: boolean = false;
  streamTimer: Subscription | undefined;
  lastUpdate: Date | undefined;
  live: boolean = true;

  spaceStatusNoEquipment: SpaceStatus = {
    charging: {
      status: ChargingStateEnum.NOT_AVAILABLE,
      startTime: '',
    },
    occupancy: {
      status: OccupancyStateEnum.NOT_AVAILABLE,
      startTime: '',
      userRegistration: '',
    },
    usage: UsageState.NOT_AVAILABLE,
  };

  spaceStatus2: SpaceStatus = {
    charging: {
      status: ChargingStateEnum.NOT_AVAILABLE,
      startTime: '',
    },
    occupancy: {
      status: OccupancyStateEnum.OCCUPIED,
      startTime: '',
      userRegistration: '',
    },
    usage: UsageState.VALID,
  };

  testStatus = {
    occupancy: 'occupied',
    charging_status: 'AVAILABLE',
    id: 'urn:ngsi-ld:ParkingSpot:7:20:230',
    type: 'ParkingSpot',
    usage_status: 'VALID',
  };

  placesOption: any;
  zonesOptions: any;

  spaceInfoSelected: SpaceInfo | undefined;

  spacesWithInfo: SpaceInfo[] | undefined;
  places: any[] | undefined;
  identifiedAreas: any[] | undefined;
  spaces: any[] | undefined;
  allSpaces: any[] | undefined;

  serverSentEvent: Subscription | undefined;

  constructor(
    private _sasUpdateService: SasUpdateService,
    private _sasPlaceService: PlaceService,
    private _sasIdentifiedAreaService: IdentifiedAreasService,
    private _sasSpacesService: SpacesService,
    private _sasSpacesStatusService: SpacesStatusService,
    private _sasBookingsService: BookingsService,
    private _authService: AuthService,
    private _storageService: StorageService
  ) {}

  ngOnInit() {
    console.log('OnInit');

    this._sasPlaceService.getPlaces().subscribe((places) => {
      this.places = places;
      this.placesOption = places[0].parkingSite.id;
      this.onPlacesOptionChange(this.placesOption);
    });

    this.serverSentEvent = this._sasUpdateService
      .getServerSentEvent(
        `${environment.sasAPIendpoint}/events/parkingspotupdates`
      )
      .subscribe({
        next: (event) => {
          console.log(event);

          if (event.type === 'error') {
            console.log('An error occurred.');
          }

          if (event.type === 'message') {
            if (event.data.includes('Heartbeat')) {
              console.log('Got heartbeat');

              // this.updateSpaceStatus(this.testStatus);
              this.connectedState = true;
              this.streamTimer?.unsubscribe();
              this.streamTimer = timer(16000).subscribe((t) => {
                this.connectedState = false;
              });
            } else {
              console.log(event.data);

              this.lastUpdate = new Date();

              let message = JSON.parse(event.data);

              this.updateSpaceStatus(message);
            }
          }
        },
      });
  }

  ngOnDestroy(): void {
    this.serverSentEvent?.unsubscribe();
    this._sasUpdateService.stopServerSentEvent();
    console.log('onDestroy in dashboard');
  }

  onPlacesOptionChange(placeId: number) {
    this._sasSpacesService.getSpaces(placeId).subscribe((spaces) => {
      this.allSpaces = spaces;
      this._sasIdentifiedAreaService
        .getIdentifiedAreas(placeId)
        .subscribe((identifiedAreas) => {
          this.identifiedAreas = identifiedAreas;
          this.zonesOptions = identifiedAreas[0].id;
          this.onZonesOptionChange(this.zonesOptions);
        });
    });
  }

  onZonesOptionChange(identifiedAreaId: number) {
    this.spaces = this.allSpaces?.filter((space: any) => {
      return space.parkingSectionId.id == identifiedAreaId;
    });

    this._sasSpacesStatusService
      .getSpacesStatus(identifiedAreaId)
      .subscribe((spacesStatus) => {
        console.log(spacesStatus);

        this.spacesWithInfo = this.spaces?.map(
          (space) =>
            <SpaceInfo>{
              uuidForAngularTrackBy: UUID.UUID(),
              spaceNumber: space.spotNumber,
              systemId: space.id,
              spaceStatus: this.getSpaceStatus(space.id, spacesStatus),
              selected: false,
              reservation: {
                bookingReference: '',
                licensePlate: '',
                bookingStartTime: '',
                bookingEndTime: '',
                paid: '',
              },
            }
        );

        if (this.spaceInfoSelected != null) {
          this.spaceInfoSelected.selected = false;
        }

        this.lastUpdate = new Date();
      });
  }

  getSpaceStatus(spaceId: number, spacesStatus: any[]): SpaceStatus {
    console.log(spaceId + ' ' + spacesStatus);

    let spaceStatusFromAPI = spacesStatus.filter((spaceStatus: any) => {
      return spaceStatus.sasId == spaceId;
    });

    let spaceStatus: SpaceStatus;

    console.log(spaceStatusFromAPI);

    if (spaceStatusFromAPI.length > 0) {
      spaceStatus = {
        charging: {
          status: spaceStatusFromAPI[0].charging.status,
          startTime: spaceStatusFromAPI[0].charging.startTime,
        },
        occupancy: {
          status: spaceStatusFromAPI[0].occupancy.status,
          startTime: spaceStatusFromAPI[0].occupancy.startTime,
          userRegistration: spaceStatusFromAPI[0].userRegistration,
        },
        usage: spaceStatusFromAPI[0].usageStatus,
      };
    } else {
      spaceStatus = this.spaceStatusNoEquipment;
    }

    console.log(spaceStatus);

    return spaceStatus;
  }

  spacePressed($event: MouseEvent, spaceInfo: SpaceInfo) {
    if (spaceInfo.selected) {
      spaceInfo.selected = false;
    } else {
      spaceInfo.selected = true;

      this._sasBookingsService
        .getCurrentBookingForSpace(spaceInfo.systemId)
        .subscribe((booking) => {
          console.log(booking);

          if (booking != null) {
            spaceInfo.reservation = {
              bookingStatus: '',
              firstName: '',
              lastName: '',
              place: '',
              spaceNr: '',
              bookingId: booking.id,
              bookingReference: booking.bookingReference,
              licensePlate: booking.vehicleId.registrationNumber,
              bookingStartTime: booking.startTime,
              bookingEndTime: booking.endTime,
              bookingTime: booking.creationTime,
              paid: booking.totalCost ?? 0,
              vat: 0,
            };
          }
        });

      if (
        this.spaceInfoSelected != null &&
        this.spaceInfoSelected != spaceInfo
      ) {
        this.spaceInfoSelected.selected = false;
      }

      this.spaceInfoSelected = spaceInfo;
    }
  }

  ngClassConvert(active: boolean) {
    let returnValue: string;
    if (active) {
      returnValue = 'active';
    } else {
      returnValue = 'inactive';
    }
    return returnValue;
  }

  updateSpaceStatus(data: any) {
    let spaceId = data.id.split(':').pop();
    console.log(spaceId);

    let spaceIndex = -1;

    if (this.spacesWithInfo != null) {
      spaceIndex = this.spacesWithInfo?.findIndex(
        (space) => space.systemId == spaceId
      );

      console.log(this.spacesWithInfo[spaceIndex]);

      let spaceStatus: SpaceStatus = {
        charging: {
          status: data.charging.status,
          startTime: data.charging.startTime,
        },
        occupancy: {
          status: data.occupancy.status,
          startTime: data.occupancy.startTime,
          userRegistration: data.userRegistration,
        },
        usage: data.usageStatus,
      };

      if (data.usageStatus === 'CHECK' || data.usageStatus === 'INVALID') {
        console.log('VIOLATION DETECTED');

        let alertArrayString = localStorage.getItem('alertArrayKey');

        console.log(alertArrayString);

        let alertArray = [];

        if (alertArrayString != null) {
          alertArray = JSON.parse(JSON.parse(alertArrayString));
        }

        // let alertArray = JSON.parse(localStorage.getItem('alertArrayKey') || "[]");

        console.log(
          'Update space status: ' + alertArray + ' ' + typeof alertArray
        );

        if (alertArray != null) {
          alertArray.push({ 'test alert': 'alert' });
        } else {
          alertArray = [];
          alertArray.push({ 'test alert': 'alert' });
        }

        console.log(alertArray);

        this._storageService.store('alertArrayKey', JSON.stringify(alertArray));
      }

      if (spaceIndex != -1) {
        this.spacesWithInfo[spaceIndex].spaceStatus = spaceStatus;
        this.spacesWithInfo[spaceIndex].uuidForAngularTrackBy = UUID.UUID();
      }

      console.log(this.spacesWithInfo[spaceIndex]);
    }
  }

  trackSpaceInfo(index: number, space: SpaceInfo) {
    return space.uuidForAngularTrackBy;
  }
}
