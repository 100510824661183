import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ClrDatagrid, ClrDatagridStateInterface } from '@clr/angular';
import * as dayjs from 'dayjs';
import { CurrentReservation } from 'src/app/models/reservation';
import { BookingsService } from 'src/app/services/bookings.service';
import { PlaceService } from 'src/app/services/place.service';

@Component({
  selector: 'app-current-bookings',
  templateUrl: './current-bookings.component.html',
  styleUrls: ['./current-bookings.component.css'],
})
export class CurrentBookingsComponent implements OnInit, OnDestroy {
  @ViewChild(ClrDatagrid) dg: ClrDatagrid | undefined;

  interval: any;
  placesOption: any;
  places: any[] | undefined;
  bookings: CurrentReservation[] = [];
  loading: boolean = true;
  total: number = 20;
  dataGridState: ClrDatagridStateInterface | undefined;

  constructor(
    private _sasBookingService: BookingsService,
    private _sasPlaceService: PlaceService
  ) {}

  ngOnInit(): void {
    this._sasPlaceService.getPlaces().subscribe((places) => {
      this.places = places;
      this.placesOption = places[0].parkingSite.id;
      this.placesOptionChanged(this.placesOption);
      console.log(places);
    });
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
  }

  refreshFromDataGridEvent(state: ClrDatagridStateInterface) {
    this.dataGridState = state;

    if (this.placesOption) {
      this.updateBookings();
    }
  }

  updateTimes(timeNow: dayjs.Dayjs) {
    this.bookings.forEach((booking) => {
      booking.bookingMinsRemaining = dayjs(booking.bookingEndTime).diff(
        timeNow,
        'minutes'
      );
    });
  }

  updateBookings() {
    this.loading = true;
    let timeNow = dayjs();

    // Note: bookings are returned using:
    // booking.endTime > filter.startTime and booking.startTime < filter.endTime
    this._sasBookingService
      .getBookingsForSite(
        this.placesOption,
        timeNow.subtract(10, 'minutes').toISOString(),
        timeNow.add(10, 'minutes').toISOString(),
        this.dataGridState?.page?.from,
        this.dataGridState?.page?.size,
        'BOOKING_SUCCESSFUL'
      )
      .subscribe((bookings) => {
        if (
          typeof bookings.totalSize === 'string' ||
          bookings.totalSize instanceof String
        ) {
          this.total = parseInt(bookings.totalSize);
        } else {
          this.total = bookings.totalSize;
        }

        let paginatedBookings: any[] = bookings.paginatedBookings;

        this.bookings = paginatedBookings.map(
          (booking: any) =>
            <CurrentReservation>{
              bookingId: booking.id,
              bookingReference: booking.bookingReference,
              licensePlate: booking.vehicleId.registrationNumber,
              bookingStartTime: booking.startTime,
              bookingEndTime: booking.endTime,
              bookingMinsRemaining: 0,
              spaceNr: booking.parkingSpotId.spotNumber,
            }
        );

        this.updateTimes(timeNow);

        // TODO: Use built-in datagrid sorting
        // We are using this custom sort as there seems to be issues
        // with pagination controls dissappearing when using sorting.

        this.bookings.sort(
          (a, b) => a.bookingMinsRemaining - b.bookingMinsRemaining
        );

        this.interval = setInterval(() => {
          this.updateTimes(dayjs());
        }, 10000);

        this.loading = false;
      });
  }

  placesOptionChanged(placeId: number) {
    this.placesOption = placeId;
    console.log(this.placesOption);
    clearInterval(this.interval);
    this.updateBookings();
  }
}
