<div class="clr-row">
  <div class="clr-col-8">
    <div class="clr-row">
      <div class="clr-col-md-auto">
        <clr-select-container>
          <label>Sites</label>
          <select
            clrSelect
            name="placesOption"
            [ngModel]="placesOption"
            (ngModelChange)="onPlacesOptionChange($event)"
          >
            <option *ngFor="let place of places" [value]="place.parkingSite.id">
              {{ place.parkingSite.name }}
            </option>
          </select>
        </clr-select-container>
      </div>
      <div class="clr-col-md-auto">
        <clr-select-container>
          <label>Zones</label>
          <select
            clrSelect
            name="zonesOptions"
            [ngModel]="zonesOption"
            (ngModelChange)="onZonesOptionChange($event)"
          >
            <option
              *ngFor="let idenfiedArea of identifiedAreas"
              [value]="idenfiedArea.id"
            >
              {{ idenfiedArea.sectionName }}
            </option>
          </select>
        </clr-select-container>
      </div>
    </div>
  </div>
</div>

<div class="clr-row">
  <div class="clr-col-4">
    <div class="card">
      <div class="card-header">Live Occupancy</div>
      <div class="card-block">
        <div class="clr-row graphHeight gauge no-clarity-header">
          <ngx-charts-advanced-pie-chart
            [results]="occupancyGaugeData"
            [animations]="animations"
          >
          </ngx-charts-advanced-pie-chart>
        </div>
      </div>
    </div>
  </div>
  <div class="clr-col-8">
    <div class="card">
      <div class="card-header">Live usage status</div>
      <div class="card-block">
        <div class="clr-row graphHeight gauge no-clarity-header">
          <ngx-charts-advanced-pie-chart
            [results]="violationsGaugeData"
            [animations]="animations"
          >
          </ngx-charts-advanced-pie-chart>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="clr-row">
  <div class="clr-col-12">
    <div class="card">
      <div class="card-header">Occupancy</div>
      <div class="card-block">
        <div class="clr-row">
          <clr-select-container>
            <!--            <label>Time period</label>-->
            <select
              clrSelect
              name="timePeriodOptions"
              [ngModel]="timePeriodOption"
              (ngModelChange)="onTimePeriodOptionChange($event)"
            >
              <option value="one">Today</option>
              <option value="two">Yesterday</option>
              <option value="three">This week</option>
              <option value="four">Last week</option>
              <option value="five">This month</option>
              <option value="six">Last month</option>
            </select>
          </clr-select-container>
        </div>

        <div class="clr-row occupancy">
          <ngx-charts-bar-vertical-2d
            [yScaleMax]="yScaleMax"
            [roundEdges]="false"
            [animations]="animations"
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [xAxis]="xAxis"
            [yAxis]="yAxis"
            [xAxisLabel]="xAxisLabel"
            [yAxisLabel]="yAxisLabel"
            [results]="occupancyHistoryData"
            [xAxisTickFormatting]="axisFormat"
            [barPadding]="1"
            [groupPadding]="2"
            [customColors]="customColours"
            [dataLabelFormatting]="formatDataLabel"
          >
          </ngx-charts-bar-vertical-2d>
        </div>
      </div>
    </div>
  </div>
</div>
