<div class="clr-row">
  <div class="clr-col-md-auto">
    <!--    <form clrForm class="clr-form clr-form-compact">-->
    <clr-date-container>
      <label>From date</label>
      <input
        type="date"
        name="fromDate"
        [clrDate]="fromDate"
        (clrDateChange)="fromDateChanged($event)"
      />
    </clr-date-container>
  </div>
  <div class="clr-col-md-auto">
    <clr-date-container>
      <label>To date</label>
      <input
        type="date"
        name="toDate"
        [clrDate]="toDate"
        (clrDateChange)="toDateChanged($event)"
      />
    </clr-date-container>
  </div>
</div>
<!--<div class="clr-row">-->
<!--  <div class="clr-col-lg-3 clr-col-md-4 clr-col-12">-->
<!--    <div class="card">-->
<!--      <div class="card-header">-->
<!--        Header-->
<!--      </div>-->
<!--      <div class="card-block">-->
<!--        <div class="card-title">-->
<!--          {{total}}-->
<!--        </div>-->
<!--        <div class="card-text">-->
<!--          ...-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--  <div class="clr-col-lg-3 clr-col-md-4 clr-col-12">-->
<!--    <div class="card">-->
<!--      <div class="card-header">-->
<!--        Header-->
<!--      </div>-->
<!--      <div class="card-block">-->
<!--        <div class="card-title">-->
<!--          Block-->
<!--        </div>-->
<!--        <div class="card-text">-->
<!--          ...-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--  <div class="clr-col-lg-3 clr-col-md-4 clr-col-12">-->
<!--    <div class="card">-->
<!--      <div class="card-header">-->
<!--        Header-->
<!--      </div>-->
<!--      <div class="card-block">-->
<!--        <div class="card-title">-->
<!--          Block-->
<!--        </div>-->
<!--        <div class="card-text">-->
<!--          ...-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--  <div class="clr-col-lg-3 clr-col-md-4 clr-col-12">-->
<!--    <div class="card">-->
<!--      <div class="card-header">-->
<!--        Header-->
<!--      </div>-->
<!--      <div class="card-block">-->
<!--        <div class="card-title">-->
<!--          Block-->
<!--        </div>-->
<!--        <div class="card-text">-->
<!--          ...-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
<clr-datagrid
  #datagrid
  (clrDgRefresh)="refreshFromDataGridEvent($event)"
  [clrDgLoading]="loading"
>
  <!--  <clr-dg-placeholder>We couldn't find any bookings!</clr-dg-placeholder>-->

  <clr-dg-column>Start time</clr-dg-column>
  <clr-dg-column>Booking time</clr-dg-column>
  <clr-dg-column>Reference</clr-dg-column>
  <clr-dg-column [clrDgField]="'status'">Status</clr-dg-column>
  <clr-dg-column>Last name</clr-dg-column>
  <clr-dg-column>Car park</clr-dg-column>
  <clr-dg-column>Space #</clr-dg-column>
  <clr-dg-column>License plate</clr-dg-column>
  <clr-dg-column>Duration</clr-dg-column>
  <clr-dg-column>Gross paid</clr-dg-column>
  <clr-dg-column>VAT</clr-dg-column>

  <!--  <clr-dg-column [clrDgField]="'startTime'">Start time</clr-dg-column>-->
  <!--  <clr-dg-column [clrDgField]="'bookingTime'">Booking time</clr-dg-column>-->
  <!--  <clr-dg-column [clrDgField]="'reference'">Reference</clr-dg-column>-->
  <!--  <clr-dg-column [clrDgField]="'status'">Status</clr-dg-column>-->
  <!--  <clr-dg-column [clrDgField]="'lastName'">Last name</clr-dg-column>-->
  <!--  <clr-dg-column [clrDgField]="'carPark'">Car park</clr-dg-column>-->
  <!--  <clr-dg-column [clrDgField]="'spaceNr'">Space #</clr-dg-column>-->
  <!--  <clr-dg-column [clrDgField]="'licensePlate'">License plate</clr-dg-column>-->
  <!--  <clr-dg-column [clrDgField]="'duration'">Duration</clr-dg-column>-->
  <!--  <clr-dg-column [clrDgField]="'fee'">Fee</clr-dg-column>-->
  <!--  <clr-dg-column [clrDgField]="'vat'">VAT</clr-dg-column>-->

  <clr-dg-row
    *ngFor="let booking of bookings"
    [routerLink]="['./', booking.bookingId]"
    routerLinkActive="active"
  >
    <clr-dg-cell>{{ booking.bookingStartTime | date: "medium" }}</clr-dg-cell>
    <clr-dg-cell>{{ booking.bookingTime | date: "medium" }}</clr-dg-cell>
    <clr-dg-cell>{{ booking.bookingReference }}</clr-dg-cell>
    <clr-dg-cell>{{ booking.bookingStatus }}</clr-dg-cell>
    <clr-dg-cell>{{ booking.lastName }}</clr-dg-cell>
    <clr-dg-cell>{{ booking.place }}</clr-dg-cell>
    <clr-dg-cell>{{ booking.spaceNr }}</clr-dg-cell>
    <clr-dg-cell>{{ booking.licensePlate }}</clr-dg-cell>
    <clr-dg-cell></clr-dg-cell>
    <clr-dg-cell>£{{ booking.paid | number: "1.2-2" }}</clr-dg-cell>
    <clr-dg-cell>£{{ booking.vat | number: "1.2-2" }}</clr-dg-cell>
  </clr-dg-row>

  <clr-dg-footer>
    <button
      [clrLoading]="downloadBtnState"
      (click)="downloadCSV()"
      class="btn btn-link btn-sm footer-left"
    >
      <clr-icon shape="download"></clr-icon> Download
    </button>
    <clr-dg-pagination #pagination [clrDgTotalItems]="total">
      <clr-dg-page-size [clrPageSizeOptions]="[10, 20, 50, 100]"
        >Bookings per page</clr-dg-page-size
      >
      {{ pagination.firstItem + 1 }} - {{ pagination.lastItem + 1 }} of
      {{ total }} Bookings
    </clr-dg-pagination>
  </clr-dg-footer>
</clr-datagrid>
