<div *ngIf="loading; then spinner; else checkfound"></div>

<ng-template #checkfound>
  <div *ngIf="booking; then detail; else notfound"></div>
</ng-template>

<ng-template #spinner>
  <div class="center">
    <span class="spinner">Loading...</span>
  </div>
</ng-template>

<ng-template #notfound>
  <div class="center">
    <h1>No details found.</h1>
  </div>
</ng-template>

<ng-template #detail>
  <div class="clr-row">
    <div class="clr-col">
      <h3>Reference: {{ booking?.bookingReference }}</h3>
      <h4>Status: {{ booking?.bookingStatus }}</h4>
    </div>
  </div>

  <div class="clr-row">
    <div class="clr-col-5">
      <table
        class="table table-vertical table-noborder table-compact table-rightheader"
      >
        <tbody>
          <tr>
            <th>Booking time</th>
            <td>{{ booking?.bookingTime | date: "medium" }}</td>
          </tr>
          <tr>
            <th>Start time</th>
            <td>{{ booking?.bookingStartTime | date: "medium" }}</td>
          </tr>
          <tr>
            <th>End time</th>
            <td>{{ booking?.bookingEndTime | date: "medium" }}</td>
          </tr>
          <tr>
            <th>Duration</th>
            <td>{{ booking?.bookingDuration }}</td>
          </tr>
          <br />

          <tr>
            <th>Car park name</th>
            <td>{{ booking?.place }}</td>
          </tr>
          <tr>
            <th>Spot number</th>
            <td>{{ booking?.spaceNr }}</td>
          </tr>
          <br />

          <tr>
            <th>Gross price</th>
            <td>£{{ booking?.paid | number: "1.2-2" }}</td>
          </tr>
          <tr>
            <th>VAT</th>
            <td>£{{ booking?.vat | number: "1.2-2" }}</td>
          </tr>
          <tr>
            <th>Payment status</th>
            <td>{{ booking?.paymentStatus }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="clr-col-5">
      <table
        class="table table-vertical table-noborder table-compact table-rightheader"
      >
        <tbody>
          <tr>
            <th>Name</th>
            <td>{{ booking?.firstName }} {{ booking?.lastName }}</td>
          </tr>
          <tr>
            <th>Email</th>
            <td>{{ booking?.email }}</td>
          </tr>
          <tr>
            <th>Contact</th>
            <td>{{ booking?.phoneNr }}</td>
          </tr>
          <br />

          <tr>
            <th>Vehicle registration</th>
            <td>{{ booking?.licensePlate }}</td>
          </tr>
          <tr>
            <th>Make</th>
            <td>{{ booking?.vehicleMake }}</td>
          </tr>
          <tr>
            <th>Model</th>
            <td>{{ booking?.vehicleModel }}</td>
          </tr>
          <tr>
            <th>Type</th>
            <td>{{ booking?.vehicleType }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="clr-row">
    <div class="clr-col">
      <table class="table table-compact table-noborder">
        <thead>
          <th>Date</th>
          <th>Time</th>
          <th>Booking status</th>
          <th>Payment status</th>
          <th>Spot #</th>
        </thead>
        <tbody>
          <tr *ngFor="let historyItem of history">
            <td>{{ historyItem.updatedAt | date: "longDate" }}</td>
            <td>{{ historyItem.updatedAt | date: "mediumTime" }}</td>
            <td>{{ historyItem.status }}</td>
            <td>{{ historyItem.paymentStatus }}</td>
            <td>{{ historyItem.bookingId.parkingSpotId.spotNumber }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</ng-template>
