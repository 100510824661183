<div class="clr-row">
  <div class="clr-col-md-auto">
    <clr-select-container>
      <label>Parking sites</label>
      <select
        clrSelect
        name="placesOption"
        [ngModel]="placesOption"
        (ngModelChange)="placesOptionChanged($event)"
      >
        <option *ngFor="let place of places" [value]="place.parkingSite.id">
          {{ place.parkingSite.name }}
        </option>
      </select>
    </clr-select-container>
  </div>
</div>

<clr-datagrid
  #datagrid
  (clrDgRefresh)="refreshFromDataGridEvent($event)"
  [clrDgLoading]="loading"
>
  <clr-dg-column>LPN</clr-dg-column>
  <clr-dg-column>#</clr-dg-column>
  <clr-dg-column>Mins</clr-dg-column>
  <clr-dg-column>Ref.</clr-dg-column>

  <clr-dg-placeholder>No current bookings found.</clr-dg-placeholder>

  <clr-dg-row
    *ngFor="let booking of bookings"
    [ngClass]="{
      expired: booking.bookingMinsRemaining < 0,
      active: booking.bookingMinsRemaining > 10,
      ending: booking.bookingMinsRemaining <= 10
    }"
  >
    <clr-dg-cell>{{ booking.licensePlate }}</clr-dg-cell>
    <clr-dg-cell>{{ booking.spaceNr }}</clr-dg-cell>
    <clr-dg-cell>{{ booking.bookingMinsRemaining }}</clr-dg-cell>
    <clr-dg-cell>{{ booking.bookingReference }}</clr-dg-cell>
  </clr-dg-row>

  <clr-dg-footer>
    <clr-dg-pagination
      #pagination
      [clrDgTotalItems]="total"
      [clrDgPageSize]="20"
    >
      <clr-dg-page-size [clrPageSizeOptions]="[10, 20, 50, 100]"
        >Bookings per page</clr-dg-page-size
      >
      {{ pagination.firstItem + 1 }} - {{ pagination.lastItem + 1 }} of
      {{ total }} Bookings
    </clr-dg-pagination>
  </clr-dg-footer>
</clr-datagrid>
