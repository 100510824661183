<div class="login-wrapper">
  <form [formGroup]="loginForm" class="login" (ngSubmit)="onSubmit()">
    <section class="title">
      <h3 class="welcome">Welcome to</h3>
      Save-a-Space
      <h5 class="hint">Ready to manage your Smart City Resources</h5>
    </section>
    <div class="login-group">
      <clr-input-container>
        <label for="email" class="clr-sr-only">Email</label>
        <input
          id="email"
          type="text"
          formControlName="email"
          clrInput
          placeholder="Email"
        />
      </clr-input-container>
      <clr-password-container>
        <label for="password" class="clr-sr-only">Password</label>
        <input
          id="password"
          type="password"
          formControlName="password"
          clrPassword
          placeholder="Password"
        />
      </clr-password-container>
      <button
        type="submit"
        [disabled]="!loginForm.valid"
        class="btn btn-primary"
      >
        Login
      </button>
      <a href="javascript://" class="signup"
        >Don't have an account yet. Sign up here!</a
      >
    </div>
  </form>
</div>
