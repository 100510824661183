import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-notauthorised',
  templateUrl: './notauthorised.component.html',
  styleUrls: ['./notauthorised.component.css'],
})
export class NotauthorisedComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
