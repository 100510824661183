import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { StorageService } from '../shared/services/storage.service';
import { User } from '../models/user';
import { Role } from '../models/role';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css'],
})
export class MainComponent implements OnInit {
  newAlerts: [] | undefined;
  currentUser!: User;
  sideNavPermittedRoles: Role[] = [Role.MANAGER, Role.SUPERUSER];

  constructor(
    private _authService: AuthService,
    private _router: Router,
    private _storageService: StorageService
  ) {
    this.currentUser = this._authService.currentUserValue;
  }

  ngOnInit(): void {
    this._storageService.changes.subscribe((event) => {
      console.log('Event fired ' + event.key);

      if (event.key == 'alertArrayKey') {
        let alertArray = [];

        alertArray = this._storageService.getStorage().filter((element) => {
          return element.key === 'alertArrayKey';
        });

        if (alertArray[0] != null) {
          this.updateAlerts(JSON.parse(alertArray[0].value));
        }
      }
    });

    // this._storageService.store("alertArrayKey", [{"testAlert 1":"Alert 1"}, {"testAlert 2":"Alert 2"}]);
  }

  updateAlerts(alertArray: []) {
    // console.log("Update alert array: " + alertArray + " " + alertArray.length + " " + typeof alertArray);
    this.newAlerts = alertArray;
  }

  onLogout() {
    this._authService.logout();
    this._router.navigateByUrl('/login').then(() => {});
  }

  clearAlertArray() {
    this._storageService.clear('alertArrayKey');
    this.newAlerts = undefined;
  }
}
