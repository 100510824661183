import { Component, Input } from '@angular/core';
import { SpaceInfo } from '../../../models/space-status';

@Component({
  selector: 'app-space-information',
  templateUrl: './space-information.component.html',
  styleUrls: ['./space-information.component.css'],
})
export class SpaceInformationComponent {
  @Input() spaceInfo: SpaceInfo | undefined;

  exampleDate: number = Date.now();

  constructor() {}
}
