<div class="login-wrapper">
  <form class="login">
    <section class="title">
      <h3 class="welcome">Welcome to</h3>
      Save-a-Space
      <h5 class="hint">Sign up for a new Save-a-Space account</h5>
    </section>
    <div class="login-group">
      <clr-input-container>
        <label class="clr-sr-only">First name</label>
        <input type="text" name="username" clrInput placeholder="Username" />
      </clr-input-container>
      <clr-input-container>
        <label class="clr-sr-only">Last name</label>
        <input type="text" name="username" clrInput placeholder="Username" />
      </clr-input-container>
      <clr-password-container>
        <label class="clr-sr-only">Password</label>
        <input
          type="password"
          name="password"
          clrPassword
          placeholder="Password"
        />
      </clr-password-container>
      <clr-checkbox-wrapper>
        <label>Remember me</label>
        <input type="checkbox" name="rememberMe" clrCheckbox />
      </clr-checkbox-wrapper>
      <div class="error active">Invalid user name or password</div>
      <button type="submit" class="btn btn-primary">NEXT</button>
      <a href="javascript://" class="signup">Sign up for a Company ID</a>
    </div>
  </form>
</div>
