import { Component } from '@angular/core';
import { ClarityIcons } from '@cds/core/icon';
import { StorageService } from './shared/services/storage.service';

const sasIconSvg = `<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="18" cy="18" r="18" fill="white"/>
<g clip-path="url(#clip0_703_23847)">
<path d="M8.67402 21.5948H26.2624V14.3418H8.64856L8.67402 21.5948Z" fill="#6C6CE5"/>
<path d="M18.2687 10.9487L18.7992 11.4793L21.9101 8.36795L20.3843 6.84095C20.2435 6.98601 20.0753 7.10162 19.8895 7.18106C19.7036 7.2605 19.5038 7.30219 19.3016 7.30369C19.0995 7.3052 18.8991 7.26649 18.7121 7.18982C18.525 7.11315 18.3551 7.00006 18.2122 6.85711C18.0693 6.71416 17.9562 6.54421 17.8795 6.35716C17.8029 6.1701 17.7642 5.96967 17.7657 5.76751C17.7672 5.56536 17.8089 5.36552 17.8883 5.17963C17.9677 4.99373 18.0833 4.82549 18.2284 4.68468L16.7917 3.25L13.6819 6.36131L13.9175 6.59688L13.7679 6.75181L13.5323 6.5173L8.36836 11.6766C8.36836 11.6766 3.96728 16.888 8.67393 21.5952L18.6475 11.6204L18.1223 11.0898L18.2687 10.9487ZM14.5933 7.57208L13.9928 6.97147L14.1392 6.82185L14.7397 7.42246L14.5933 7.57208ZM15.4177 8.39766L14.8183 7.79705L14.9679 7.64743L15.5684 8.24804L15.4177 8.39766ZM16.2432 9.22324L15.6427 8.62263L15.7933 8.473L16.3939 9.07256L16.2432 9.22324ZM17.0687 10.0488L16.4734 9.44821L16.6188 9.29858L17.2161 9.89814L17.0687 10.0488ZM17.8941 10.8733L17.2936 10.2738L17.4443 10.1231L18.0437 10.7237L17.8941 10.8733Z" fill="#4024F3"/>
<path d="M26.2625 14.3408L15.9706 24.634L16.2529 24.9173L16.1032 25.067L15.8189 24.7911L13.0316 27.5745L14.5531 29.0951C14.6947 28.9535 14.8629 28.8412 15.0479 28.7646C15.2329 28.688 15.4312 28.6486 15.6315 28.6487C15.8317 28.6487 16.03 28.6882 16.215 28.7649C16.4 28.8416 16.5681 28.954 16.7096 29.0956C16.8512 29.2373 16.9635 29.4054 17.0401 29.5905C17.1166 29.7756 17.156 29.9739 17.156 30.1742C17.1559 30.3744 17.1164 30.5727 17.0398 30.7578C16.9631 30.9428 16.8507 31.1109 16.7091 31.2524L18.1436 32.6871L20.9298 29.8995L20.4523 29.4219L20.603 29.2713L21.0794 29.7488L26.5691 24.2605C26.5691 24.2605 30.9691 19.0481 26.2625 14.3408ZM16.9255 25.8947L16.325 25.2951L16.4735 25.1444L17.0773 25.7461L16.9255 25.8947ZM17.751 26.7202L17.1505 26.1196L17.3001 25.97L17.9006 26.5706L17.751 26.7202ZM18.5754 27.5458L17.9759 26.9452L18.1255 26.7956L18.7261 27.3962L18.5754 27.5458ZM19.4009 28.3714L18.8078 27.7708L18.9584 27.6212L19.559 28.2207L19.4009 28.3714ZM20.2263 29.197L19.6258 28.5964L19.7765 28.4467L20.376 29.0463L20.2263 29.197Z" fill="#09C6FF"/>
<path opacity="0.3" d="M19.0104 21.5948L23.7839 14.3418H26.2412L19.0104 21.5948Z" fill="#4024F3"/>
<path opacity="0.3" d="M15.9271 14.3408L11.1791 21.5482H8.7207L15.9271 14.3408Z" fill="#4024F3"/>
</g>
<defs>
<clipPath id="clip0_703_23847">
<rect width="21.9375" height="29.4375" fill="white" transform="translate(6.5 3.25)"/>
</clipPath>
</defs>
</svg>`;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'sas-dashboard-v2';

  constructor(private _storageService: StorageService) {
    ClarityIcons.addIcons(['sas-icon', sasIconSvg]);
  }
}
