<ng-container
  *ngIf="spaceInfo?.selected; then showSpaceNumber; else showSelectText"
>
</ng-container>

<ng-template #showSelectText>
  <div class="row">
    <p>Select a space to display its status</p>
  </div>
</ng-template>

<ng-template #showSpaceNumber>
  <div class="row">
    <p>Selected space number: {{ spaceInfo?.spaceNumber }}</p>
  </div>
</ng-template>

<div *ngIf="spaceInfo?.selected" class="row">
  <table class="table">
    <caption>
      Space monitoring information
    </caption>
    <tbody>
      <tr>
        <td class="column-one">Arrival time</td>
        <td class="column-two">
          {{ spaceInfo?.spaceStatus?.occupancy?.startTime | date: "medium" }}
        </td>
      </tr>
      <tr>
        <td class="column-one">Space occupancy status</td>
        <td class="column-two">
          {{ spaceInfo?.spaceStatus?.occupancy?.status }}
        </td>
      </tr>
      <tr>
        <td class="column-one">Enforcement action required</td>
        <td class="column-two">{{ spaceInfo?.spaceStatus?.usage }}</td>
      </tr>
      <tr>
        <td class="column-one">Tag identifier</td>
        <td class="column-two">
          {{ spaceInfo?.spaceStatus?.occupancy?.userRegistration }}
        </td>
      </tr>
    </tbody>
  </table>
</div>
<div *ngIf="spaceInfo?.selected" class="row">
  <table class="table">
    <caption>
      Charging Information
    </caption>
    <tbody>
      <tr>
        <td class="column-one">Charger status</td>
        <td class="column-two">
          {{ spaceInfo?.spaceStatus?.charging?.status }}
        </td>
      </tr>
      <tr>
        <td class="column-one">Charging start time</td>
        <td class="column-two">
          {{ spaceInfo?.spaceStatus?.charging?.startTime | date: "medium" }}
        </td>
      </tr>
      <tr>
        <td class="column-one">Charging end time</td>
        <td class="column-two">N/A</td>
      </tr>
    </tbody>
  </table>
</div>
<div *ngIf="spaceInfo?.selected" class="row">
  <table class="table">
    <caption>
      Booking information
    </caption>
    <tbody>
      <tr>
        <td class="column-one">Booking reference</td>
        <td class="column-two">
          {{ spaceInfo?.reservation?.bookingReference }}
        </td>
      </tr>
      <tr>
        <td class="column-one">License plate number</td>
        <td class="column-two">{{ spaceInfo?.reservation?.licensePlate }}</td>
      </tr>
      <tr>
        <td class="column-one">Booking time</td>
        <td class="column-two">
          {{ spaceInfo?.reservation?.bookingTime | date: "medium" }}
        </td>
      </tr>
      <tr>
        <td class="column-one">Booking start time</td>
        <td class="column-two">
          {{ spaceInfo?.reservation?.bookingStartTime | date: "medium" }}
        </td>
      </tr>
      <tr>
        <td class="column-one">Booking end time</td>
        <td class="column-two">
          {{ spaceInfo?.reservation?.bookingEndTime | date: "medium" }}
        </td>
      </tr>
      <!--    <tr>-->
      <!--      <td class="column-one">Booking duration</td>-->
      <!--      <td class="column-two">4 hrs</td>-->
      <!--    </tr>-->
      <tr>
        <td class="column-one">Fee paid</td>
        <td class="column-two">
          £{{ spaceInfo?.reservation?.paid | number: "1.2-2" }}
        </td>
      </tr>
    </tbody>
  </table>
</div>
