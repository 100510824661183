<div class="clr-row">
  <div class="clr-col-8">
    <div class="clr-row">
      <div class="clr-col-md-auto">
        <clr-select-container>
          <label>Parking sites</label>
          <select
            clrSelect
            name="placesOption"
            [ngModel]="placesOption"
            (ngModelChange)="onPlacesOptionChange($event)"
          >
            <option *ngFor="let place of places" [value]="place.parkingSite.id">
              {{ place.parkingSite.name }}
            </option>
          </select>
        </clr-select-container>
      </div>
      <div class="clr-col-md-auto">
        <clr-select-container>
          <label>Zones</label>
          <select
            clrSelect
            name="zonesOptions"
            [ngModel]="zonesOptions"
            (ngModelChange)="onZonesOptionChange($event)"
          >
            <option
              *ngFor="let idenfiedArea of identifiedAreas"
              [value]="idenfiedArea.id"
            >
              {{ idenfiedArea.sectionName }}
            </option>
          </select>
        </clr-select-container>
      </div>
    </div>
    <div
      class="clr-row"
      style="overflow: auto; height: auto; max-height: calc(100vh - 115px)"
    >
      <div
        class="clr-col-sm-auto space-positioning"
        [ngClass]="'space-background-' + ngClassConvert(spaceInfo.selected)"
        *ngFor="let spaceInfo of spacesWithInfo; trackBy: trackSpaceInfo"
        (click)="spacePressed($event, spaceInfo)"
      >
        <app-space [spaceInfo]="spaceInfo"></app-space>
      </div>
    </div>
  </div>
  <div class="clr-col-4">
    <div class="row">
      <div class="clr-col-md-auto" style="padding: 0; margin-bottom: 20px">
        <cds-alert-group
          *ngIf="connectedState === true"
          status="success"
          aria-label="Live data streams connected."
        >
          <cds-alert *ngIf="lastUpdate === null" status="success">
            Live data connected. No status update received yet.
          </cds-alert>
          <cds-alert *ngIf="lastUpdate !== null" status="success">
            Live data connected. Last status update
            {{ lastUpdate | timeago: live }}.
          </cds-alert>
        </cds-alert-group>
        <cds-alert-group
          *ngIf="connectedState === false"
          status="danger"
          aria-label="Live streams not connected. Last update: 2 mins ago."
        >
          <cds-alert *ngIf="lastUpdate === null" status="danger">
            Live data not connected. No status update received yet.
          </cds-alert>
          <cds-alert *ngIf="lastUpdate !== null" status="danger">
            Live data not connected. Last status update
            {{ lastUpdate | timeago: live }}.
          </cds-alert>
        </cds-alert-group>
      </div>
    </div>
    <app-space-information
      [spaceInfo]="spaceInfoSelected"
    ></app-space-information>
  </div>
</div>
