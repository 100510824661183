<p>{{ spaceInfo?.spaceNumber }}</p>
<div style="position: relative; left: 0; top: 0">
  <img
    src="assets/space-component/spaces-status/{{ backgroundImageName }}"
    class="space-background"
    alt=""
  />
  <img
    *ngIf="
      spaceInfo?.spaceStatus?.occupancy?.status !==
        occupancySateEnum.NOT_AVAILABLE &&
      spaceInfo?.spaceStatus?.occupancy?.status !== occupancySateEnum.FREE
    "
    src="assets/space-component/Car_occupied.svg"
    class="car-overlay"
    alt=""
  />
  <img
    *ngIf="
      spaceInfo?.spaceStatus?.occupancy?.status !==
      occupancySateEnum.NOT_AVAILABLE
    "
    src="assets/space-component/status/{{ usageImageName }}"
    class="violation-overlay"
    alt=""
  />
  <img
    *ngIf="
      spaceInfo?.spaceStatus?.charging?.status !== chargeStateEnum.NOT_AVAILABLE
    "
    src="assets/space-component/plug/{{ chargingImageName }}"
    class="ev-connector-overlay"
    alt=""
  />
</div>
