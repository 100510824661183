import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class BookingsService {
  constructor(private _http: HttpClient) {}

  getCurrentBookingForSpace(spaceId: number): Observable<any> {
    return this._http.get<any>(
      `${environment.sasAPIendpoint}/parkingspots/${spaceId}/bookings?filter=current`
    );
  }

  getBookings(
    fromDate: string,
    toDate: string,
    startIndex: number = -1,
    pageSize: number = -1,
    status: string
  ): Observable<any> {
    console.log(`startTime: ${fromDate}`);
    console.log(`endTime: ${toDate}`);
    console.log(`startIndex: ${startIndex}`);
    console.log(`pageSize: ${pageSize}`);
    console.log(`status: ${status}`);

    return this._http.get<any>(
      `${environment.sasAPIendpoint}/bookings?startTime=${fromDate}&endTime=${toDate}&startIndex=${startIndex}&pageSize=${pageSize}&status=${status}`
    );
  }

  getBookingsCSV(
    fromDate: string,
    toDate: string,
    status: string
  ): Observable<Blob> {
    return this._http.get(
      `${environment.sasAPIendpoint}/bookings/download?startTime=${fromDate}&endTime=${toDate}&status=${status}`,
      { responseType: 'blob' }
    );
  }

  getBookingHistory(bookingId: number): Observable<any> {
    return this._http.get<any>(
      `${environment.sasAPIendpoint}/bookings/${bookingId}/history`
    );
  }

  getBookingsForSite(
    placeId: number,
    fromDate: string,
    toDate: string,
    startIndex: number = -1,
    pageSize: number = -1,
    status: string
  ): Observable<any> {
    return this._http.get<any>(
      `${environment.sasAPIendpoint}/parkingsites/${placeId}/bookings?status=${status}&startTime=${fromDate}&endTime=${toDate}&startIndex=${startIndex}&pageSize=${pageSize}`
    );
  }
}
